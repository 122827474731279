import React, { useEffect, useRef } from "react";
import icons from "../images/Icons.svg";
import toughts from "../images/BB 1.png";
import dreams from "../images/BB 2.png";

function Mission({}, ref) {
  const refImg = useRef(null);
  let images = [false, false];

  const loadingImages = (index) => {
    images[index] = true;
  };

  return (
    <div className="mission" ref={ref}>
      <img src={icons} alt="icons" className="icons" />
      <div className="images">
        <div className="dreams">
          <img src={dreams} onLoad={() => loadingImages(0)} />
        </div>
        <div className="toughts">
          <img src={toughts} ref={refImg} onLoad={() => loadingImages(1)} />
        </div>
      </div>
    </div>
  );
}

const forwardRef = React.forwardRef(Mission);

export default forwardRef;
