export const scrollObserver = (elements, reached) => {
  let offsets = elements.map((element) => element.current.offsetTop);
  let newReached = [...reached];
  let change = false;
  for (let i = 0; i < offsets.length; i++) {
    let reachedPoint = window.scrollY >= offsets[i] - window.innerHeight;
    if (!newReached[i] && reachedPoint) {
      newReached[i] = true;
      change = true;
    } else if (newReached[i] && !reachedPoint) {
      newReached[i] = false;
      change = true;
    }
  }
  return [change, newReached];
};
