import { useRef } from "react";
import "./App.css";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Mission from "./components/Mission";
import PageLocation from "./components/PageLocation";
import Services from "./components/Services";

function App() {
  const headerRef = useRef(null);
  const missionRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      <PageLocation
        elements={[headerRef, missionRef, servicesRef, contactRef]}
      />
      <Header ref={headerRef} />
      <Mission ref={missionRef} />
      <Services ref={servicesRef} />
      <Contact ref={contactRef} />
      <Footer />
    </>
  );
}

export default App;
