import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "react-toastify/dist/ReactToastify.css";

if (localStorage.getItem("lang") === "arabic") require("./styles/rtl.css");
else require("./styles/ltr.css");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
