import React from "react";
import Form from "./Form";

function Contact({}, ref) {
  return (
    <div className="contact" ref={ref}>
      <p>
        Let's begin <span>the work </span>together
      </p>
      <Form />
    </div>
  );
}

const forwardRef = React.forwardRef(Contact);

export default forwardRef;
