import React from "react";
import logo from "../images/besharp logo.png";

function Footer() {
  return (
    <>
      <div className="stretching"></div>
      <footer>
        <img src={logo} alt="be sharp logo" />
        <div className="social-media">
          <i className="lab la-facebook" />
          <i className="lab la-instagram" />
          <i className="lab la-twitter" />
          <i className="lab la-linkedin" />
        </div>
        <p>
          <i className="las la-map-marker-alt" />
          <span>Ottawa, Canada</span>
        </p>
        <a href="tel:+1 514 560 6221">
          <i className="las la-mobile-alt" />
          <span>+1 514 560 6221</span>
        </a>
        <a href="mailto:info@besharp.dev">
          <i className="las la-envelope" />
          <span>info@besharp.dev</span>
        </a>
        <p className="rights">
          &copy; {new Date().getFullYear()} BeSharp, All rights reserved.
        </p>
      </footer>
    </>
  );
}

export default Footer;
