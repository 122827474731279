import React, { useEffect, useState } from "react";
import { scrollObserver } from "../services/scroll";

function PageLocation({ elements }) {
  const [reached, setReached] = useState([true, false, false, false]);

  const handleScroll = () => {
    const [result, newReached] = scrollObserver(elements, reached);
    if (result) setReached(newReached);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [reached]);

  return (
    <div className="progress">
      {reached.map((element, index) => (
        <div key={index} className={element ? "reached" : "not-reached"}></div>
      ))}
    </div>
  );
}

export default PageLocation;
