import React from "react";
import logo from "../images/besharp logo.png";
import odoo from "../images/odoo.svg";

function Header({}, ref) {
  return (
    <header className="main-header" ref={ref} id="home">
      <div className="navbar">
        <img src={logo} alt="be sharp logo" />
        {/* <ul>
          <li>
            <DropDown />
          </li>
        </ul> */}
      </div>
      <div className="header-paragraph">
        <p>
          <span>Be</span>
          <span>Sharp </span>
          <br />
          Complete
          <br /> Software
          <br /> Development
          <button>START NOW</button>
        </p>
      </div>
      <div className="header-end">
        <div className="flexing">
          <span>
            Let your work <span className="be">BE</span>
            <span className="sharp"> SHARP</span> with
          </span>
          <img src={logo} alt="be sharp logo" />
        </div>
        <p className="header-odoo">
          <span>In Partnership With </span>
          <img src={odoo} alt="odoo" />
        </p>
      </div>
    </header>
  );
}

const forwardRef = React.forwardRef(Header);
export default forwardRef;
