import React from "react";
import app from "../images/app.svg";
import uiux from "../images/ux.svg";
import solution from "../images/dev.svg";

function Services({}, ref) {
  return (
    <div className="services" ref={ref} id="services">
      <div className="paragraphs">
        <p className="what">WHAT</p>
        <p className="we-do">WE DO</p>
      </div>
      <div className="paragraphs">
        <p>Custom</p>
        <p>Tailored</p>
        <p>Solutions</p>
      </div>
      <div className="besharp-services">
        <div className="service">
          <div>
            <h3>App Development</h3>
            <img src={app} />
          </div>
        </div>
        <div className="service">
          <div>
            <h3>
              UI/UX Design
              <br />
            </h3>
            <img src={uiux} />
          </div>
        </div>
        <div className="service">
          <div>
            <h3>
              Web Development <br />
            </h3>
            <img src={solution} />
          </div>
        </div>
      </div>
    </div>
  );
}

const forwardRef = React.forwardRef(Services);

export default forwardRef;
