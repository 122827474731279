import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";

function Form() {
  const [user, setUser] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleChange = ({ currentTarget }) => {
    let newUser = { ...user };
    newUser[currentTarget.name] = currentTarget.value;
    setUser(newUser);
  };

  const handleSubmit = async () => {
    if (!user.message || !user.user_email || !user.user_name) {
      toast.error("Please fill the entire fields correctly");
      return;
    }
    try {
      setLoading(true);
      await emailjs.sendForm(
        "service_gw4sxl8",
        "template_z2l9cja",
        formRef.current,
        "Eb3HwzcqDk9t_lcLt"
      );
      setUser({
        user_name: "",
        user_email: "",
        message: "",
      });
      toast.success("Your email has been sent successfully");
      setLoading(false);
    } catch (err) {
      toast.error("There was a connection error, try again please");
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="form">
        <form ref={formRef}>
          <div>
            <label>
              Name
              <input
                type="text"
                onChange={handleChange}
                name="user_name"
                value={user.user_name}
              />
            </label>
            <label>
              Email
              <input
                type="text"
                onChange={handleChange}
                name="user_email"
                value={user.user_email}
              />
            </label>
          </div>
          <div>
            <label>
              Message
              <textarea
                rows="6"
                onChange={handleChange}
                name="message"
                value={user.message}
              />
            </label>
          </div>
        </form>
        <button onClick={handleSubmit}>
          {!loading ? "REACH US" : <div className="loader"></div>}
        </button>
      </div>
    </>
  );
}

export default Form;
